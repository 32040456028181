<template>
<div>
    <v-toolbar flat height="70" v-if="!$vuetify.breakpoint.mdAndUp">
        <span class="title primary--text">ТОП пропозиції</span>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="drawerOpenClose()">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
    <v-divider v-if="!$vuetify.breakpoint.mdAndUp"></v-divider>
    <v-list>
        <v-list-item-group>
            <div v-for="item in list" :key="item.id">
                <v-list-item>
                    <v-list-item-content @click="loadData(item.id)">
                        <v-list-item-title :class="`task-item my-2 ` + (item.selected  ? `primary--text`:``)">
                            {{item.name}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider>
                </v-divider>
            </div>
        </v-list-item-group>
    </v-list>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    props: {
        drawer: Boolean
    },
    data() {
        return {
            selected: NaN
        }
    },
    methods: {
        ...mapActions(['touch', 'getShoppingGuideData', 'getTasksList', 'getTasksFilters']),
        drawerOpenClose() {
            this.$store.commit('openCloseShoppingTasksDrawer', !this.shoppingTasksDrawerOpened);
        },

        loadData(id) {
            this.$store.commit('openCloseShoppingTasksDrawer', false)
            this.touch()
                .then(() => {
                    this.selected = id
                    this.getShoppingGuideData({
                            id
                        })
                        .then(data => {
                            const filterModel = {}
                            this.filters.map(el => filterModel[el.value] = true)
                            this.$store.commit('setShoppingTasksFilterModel', filterModel)

                            const filters = Object.keys(this.filterModel).filter(el => {
                                if (this.filterModel[el]) {
                                    return el
                                }
                            }).join(',')
                            this.getShoppingGuideData({
                                    id,
                                    filters
                                })
                                .then(data => {
                                    const filterModel = {}
                                    data.filters.map(el => filterModel[el.value] = true)
                                    this.$store.commit('setShoppingTasksFilterModel', filterModel)
                                    this.getTasksList(id)
                                })
                        })

                })
        },
    },
    created() {
        this.getTasksList()
            .then(data => {
                const id = (data[0] && data[0].id) || 0
                this.loadData(id)

            })
    },
    computed: {
        list() {
            return this.$store.state.catalog.shoppingGuideTasksList
        },
        shoppingTasksDrawerOpened: {
            get: function () {
                return this.$store.state.catalog.shoppingTasksDrawerOpened
            },
            set: function (v) {
                this.$store.commit('openCloseShoppingTasksDrawer', v);
            }
        },
        filterModel() {
            return this.$store.state.catalog.shoppingTasksFilterModel
        },
        filters() {
            return this.$store.state.catalog.shoppingTasksFilters
        },

    }
}
</script>

<style scoped>
.task-item {
    white-space: normal;
}
</style>
