<template>
<v-container fluid>
    <h1 class="mb-4">ТОП пропозиції</h1>

    <v-row no-gutters v-if="!list.length && !loading">
        <v-card tale flat width="100%" class="pa-8 px-md-1">
            <div class="subtitle text-center pa-6">
                Немає пропозицій.
            </div>
        </v-card>
    </v-row>
    <v-row no-gutters v-show="list.length">
        <v-col xl="2" lg="2" md="3" class="pr-2" v-if="$vuetify.breakpoint.mdAndUp">
            <ShoppingGuideLeftList />
        </v-col>
        <v-col xl="10" lg="10" md="9" class="px-0 px-md-2">
            <v-card flat tile width="100%" v-if="!$vuetify.breakpoint.mdAndUp" class="mb-4">
                <v-btn color="primary" block dark @click.stop="drawerOpenClose()">
                    Всі пропозиції
                    <v-icon right>mdi-filter-menu-outline</v-icon>
                </v-btn>
            </v-card>

            <div v-if="shoppingTasksPagination.current" class="d-flex justify-space-between align-center py-4 px-2 grey lighten-3 rounded">
                <span>
                    <v-btn rounded icon @click="loadNewData(shoppingTasksPagination.prev.id )" v-if="shoppingTasksPagination.prev">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </span>
                <span :class="
              `primary--text ` +
              ($vuetify.breakpoint.mdAndUp ? `title` : `subtitle-1`)
            ">{{ shoppingTasksPagination.current.name }}</span>
                <span>
                    <v-btn rounded icon @click="loadNewData(shoppingTasksPagination.next.id, true)" v-if="shoppingTasksPagination.next">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </span>
            </div>
            <!-- {{filters}}
                {{filterModel}} -->
            <v-card class="my-4 blue lighten-5 px-0" flat>
                <v-card-text>
                    <h4>Фільтр за категорією:</h4>
                    <div v-for="item in filters" :key="item.value" class="d-inline-block pa-0 mr-4">
                        <v-checkbox @click="loadData(item.taskId)" v-model="filterModel[item.value]" hide-details>
                            <template v-slot:label>
                                <span class="subtitle-2">{{item.text}}</span>
                            </template>
                        </v-checkbox>
                    </div>
                </v-card-text>
            </v-card>

            <v-card tale flat width="100%" v-if="shoppingGuideList.length">
                <v-card-title :class="
              `text-weight-bold px-0 px-md-4 pb-0 ` +
              ($vuetify.breakpoint.mdAndUp ? `subtitle-1` : `subtitle-2`)
            ">
                    Зверніть увагу на позиції:
                </v-card-title>
                <List shoppingGuide hideNavigation hideSorting disableSorting disablePagination ignoreBlockViewMode />
            </v-card>
            <v-card tale flat width="100%" v-show="shoppingGuideActionsList.length">
                <v-card-title v-if="shoppingGuideList.length" :class="
              `text-weight-bold px-0 px-md-3 pb-0 ` +
              ($vuetify.breakpoint.mdAndUp ? `subtitle-1` : `subtitle-2`)
            ">
                    Інші товари:
                </v-card-title>
                <List shoppingGuideActions hideNavigation hideSorting disableSorting ignoreBlockViewMode />
            </v-card>
            <v-card tale flat width="100%" class="pa-8 px-md-1" v-if="
            !shoppingGuideList.length &&
            !shoppingGuideActionsList.length &&
            !loading
          ">
                <div class="subtitle text-center pa-6">
                    Нажаль, товари пропозиції недоступні.
                </div>
            </v-card>
        </v-col>
        <v-navigation-drawer v-if="!$vuetify.breakpoint.mdAndUp" v-model="shoppingTasksDrawerOpened" stateless clipped temporary app width="320">
            <ShoppingGuideLeftList />
        </v-navigation-drawer>
    </v-row>
</v-container>
</template>

<script>
import {
    mapActions
} from "vuex";

import List from "./List.vue";
import ShoppingGuideLeftList from "./ShoppingGuideLeftList.vue";

export default {
    components: {
        ShoppingGuideLeftList,
        List,
    },
    props: {},
    data() {
        return {

        }
    },
    methods: {
        ...mapActions(["touch", "getShoppingGuideData", "getTasksList"]),
        drawerOpenClose() {
            this.$store.commit(
                "openCloseShoppingTasksDrawer",
                !this.shoppingTasksDrawerOpened
            );
        },
        loadNewData(id) {
            this.$store.commit('openCloseShoppingTasksDrawer', false)
            this.touch()
                .then(() => {
                    this.selected = id
                    this.getShoppingGuideData({
                            id
                        })
                        .then(data => {
                            const filterModel = {}
                            this.filters.map(el => filterModel[el.value] = true)
                            this.$store.commit('setShoppingTasksFilterModel', filterModel)

                            const filters = Object.keys(this.filterModel).filter(el => {
                                if (this.filterModel[el]) {
                                    return el
                                }
                            }).join(',')
                            this.getShoppingGuideData({
                                    id,
                                    filters
                                })
                                .then(data => {
                                    const filterModel = {}
                                    data.filters.map(el => filterModel[el.value] = true)
                                    this.$store.commit('setShoppingTasksFilterModel', filterModel)
                                    this.getTasksList(id)
                                })
                        })

                })
        },
        loadData(id) {
            this.$store.commit("openCloseShoppingTasksDrawer", false);

            this.touch().then(() => {
                const filters = Object.keys(this.filterModel).filter(el => {
                    if (this.filterModel[el]) {
                        return el
                    }
                }).join(',')

                this.getTasksList(id).then(() => {
                    this.getShoppingGuideData({
                            id,
                            filters
                        })
                        .then(data => {
                            this.getShoppingGuideData({
                                id,
                                filters
                            })
                        })
                })
            });
        },
    },
    watch: {},
    created() {},
    computed: {
        shoppingTasksDrawerOpened: {
            get: function () {
                return this.$store.state.catalog.shoppingTasksDrawerOpened;
            },
            set: function (v) {
                this.$store.commit("openCloseShoppingTasksDrawer", v);
            },
        },
        shoppingGuideActionsList() {
            return this.$store.state.catalog.shoppingGuideActionsList;
        },
        shoppingGuideList() {
            return this.$store.state.catalog.shoppingGuideList;
        },
        shoppingTasksPagination() {
            return this.$store.state.catalog.shoppingTasksPagination;
        },
        loading() {
            return this.$store.state.catalog.listLoading;
        },
        list() {
            return this.$store.state.catalog.shoppingGuideTasksList;
        },
        filters() {
            return this.$store.state.catalog.shoppingTasksFilters
        },
        filterModel() {
            return this.$store.state.catalog.shoppingTasksFilterModel
        }
    },
};
</script>

<style>
.v-data-table.desc tr,
.v-data-table.desc tr:hover {
    background: #e3f2fd !important;
}
</style>
