<template>
    <ShoppingGuide />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ShoppingGuide from '../components/ShoppingGuidePage.vue'

export default {
    data: () => ({
    }),
    components: {
       ShoppingGuide
    },
    methods: {
    },
    mounted() {
    }
}
</script>
