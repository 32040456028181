var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"mb-4"},[_vm._v("ТОП пропозиції")]),(!_vm.list.length && !_vm.loading)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"pa-8 px-md-1",attrs:{"tale":"","flat":"","width":"100%"}},[_c('div',{staticClass:"subtitle text-center pa-6"},[_vm._v(" Немає пропозицій. ")])])],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.length),expression:"list.length"}],attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pr-2",attrs:{"xl":"2","lg":"2","md":"3"}},[_c('ShoppingGuideLeftList')],1):_vm._e(),_c('v-col',{staticClass:"px-0 px-md-2",attrs:{"xl":"10","lg":"10","md":"9"}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card',{staticClass:"mb-4",attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-btn',{attrs:{"color":"primary","block":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.drawerOpenClose()}}},[_vm._v(" Всі пропозиції "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-filter-menu-outline")])],1)],1):_vm._e(),(_vm.shoppingTasksPagination.current)?_c('div',{staticClass:"d-flex justify-space-between align-center py-4 px-2 grey lighten-3 rounded"},[_c('span',[(_vm.shoppingTasksPagination.prev)?_c('v-btn',{attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.loadNewData(_vm.shoppingTasksPagination.prev.id )}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('span',{class:`primary--text ` +
              (_vm.$vuetify.breakpoint.mdAndUp ? `title` : `subtitle-1`)},[_vm._v(_vm._s(_vm.shoppingTasksPagination.current.name))]),_c('span',[(_vm.shoppingTasksPagination.next)?_c('v-btn',{attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.loadNewData(_vm.shoppingTasksPagination.next.id, true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)]):_vm._e(),_c('v-card',{staticClass:"my-4 blue lighten-5 px-0",attrs:{"flat":""}},[_c('v-card-text',[_c('h4',[_vm._v("Фільтр за категорією:")]),_vm._l((_vm.filters),function(item){return _c('div',{key:item.value,staticClass:"d-inline-block pa-0 mr-4"},[_c('v-checkbox',{attrs:{"hide-details":""},on:{"click":function($event){return _vm.loadData(item.taskId)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.text))])]},proxy:true}],null,true),model:{value:(_vm.filterModel[item.value]),callback:function ($$v) {_vm.$set(_vm.filterModel, item.value, $$v)},expression:"filterModel[item.value]"}})],1)})],2)],1),(_vm.shoppingGuideList.length)?_c('v-card',{attrs:{"tale":"","flat":"","width":"100%"}},[_c('v-card-title',{class:`text-weight-bold px-0 px-md-4 pb-0 ` +
              (_vm.$vuetify.breakpoint.mdAndUp ? `subtitle-1` : `subtitle-2`)},[_vm._v(" Зверніть увагу на позиції: ")]),_c('List',{attrs:{"shoppingGuide":"","hideNavigation":"","hideSorting":"","disableSorting":"","disablePagination":"","ignoreBlockViewMode":""}})],1):_vm._e(),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.shoppingGuideActionsList.length),expression:"shoppingGuideActionsList.length"}],attrs:{"tale":"","flat":"","width":"100%"}},[(_vm.shoppingGuideList.length)?_c('v-card-title',{class:`text-weight-bold px-0 px-md-3 pb-0 ` +
              (_vm.$vuetify.breakpoint.mdAndUp ? `subtitle-1` : `subtitle-2`)},[_vm._v(" Інші товари: ")]):_vm._e(),_c('List',{attrs:{"shoppingGuideActions":"","hideNavigation":"","hideSorting":"","disableSorting":"","ignoreBlockViewMode":""}})],1),(
            !_vm.shoppingGuideList.length &&
            !_vm.shoppingGuideActionsList.length &&
            !_vm.loading
          )?_c('v-card',{staticClass:"pa-8 px-md-1",attrs:{"tale":"","flat":"","width":"100%"}},[_c('div',{staticClass:"subtitle text-center pa-6"},[_vm._v(" Нажаль, товари пропозиції недоступні. ")])]):_vm._e()],1),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-navigation-drawer',{attrs:{"stateless":"","clipped":"","temporary":"","app":"","width":"320"},model:{value:(_vm.shoppingTasksDrawerOpened),callback:function ($$v) {_vm.shoppingTasksDrawerOpened=$$v},expression:"shoppingTasksDrawerOpened"}},[_c('ShoppingGuideLeftList')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }